import React from "react"
import { Layout, Row, Col } from "antd"
import { useNavigate } from "react-router-dom"
import { Avatar } from "ui/Avatar"
import { Text } from "ui/Typography"
import { Icon } from "ui/Icon"
import { routes } from "router"

import styles from "./HistoryHeader.module.css"
import classNames from "classnames"
import { colors } from "themes/variables"

const { Header } = Layout

export const HistoryHeader = ({ participant, isMobile, isTablet }) => {
  const isSmallScreen = isMobile || isTablet
  const navigate = useNavigate()

  return (
    <Header className={classNames(styles.header, isSmallScreen && styles.smallScreen)}>
      {participant?.id && (
        <Row className={styles.row} justify={isSmallScreen ? "space-between" : "start"} align="middle">
          <Col>
            <Avatar src={participant.avatar} fullName={participant.full_name} size={isMobile ? 40 : 52} />
          </Col>
          <Col className="ml-m">
            <div className={classNames("flex-column", isSmallScreen && "align-center")}>
              <Text variant="sub" size="lg">
                {participant.full_name}
              </Text>
              <Text variant="caption" type="secondary">
                Was active recently
              </Text>
            </div>
          </Col>
          {isSmallScreen && (
            <Col onClick={() => navigate(routes.proMessagesRootPath())}>
              <Icon type="feather" name="ChevronLeft" width={24} height={24} color={colors.textSecondary} />
            </Col>
          )}
        </Row>
      )}
    </Header>
  )
}
