import { dynamicRoute } from "./routes"

export const ROUTES_LIST = {
  rootPath: "/old/",
  becomeAGuidePath: "/old/become-a-guide",
  faqPath: "/old/faq",
  termsAndConditionsPath: "/old/terms-and-conditions",
  privacyPolicyPath: "/old/privacy-policy",
  tripsPath: "/old/trips",
  tripPath: "/old/trips/:id",
  referralPath: "/old/referral",
  salesPath: "/old/sales",

  // Devise routes
  signInPath: "/old/sign-in",
  signUpPath: "/old/sign-up",
  signUpRequestSentPath: "/old/sign-up/request-sent",
  forgotPasswordPath: "/old/forgot-password",
  resetPasswordRequestSentPath: "/old/reset-password/request-sent",
  resetPasswordPath: "/old/reset-password",
  resetPasswordSuccessPath: "/old/reset-password/success",
  verifyAccountPath: "/old/verify-account",
  verifyCodePath: "/old/verify-code",
  splashPath: "/old/splash",

  // Booking routes
  bookingWizardPath: "/old/booking_trips/wizard",

  guestBookingWizardRootPath: "/old/guest/booking_trips/:uuid/wizard",
  guestBookingWizardStep1Path: "/old/guest/booking_trips/:uuid/wizard/booking",
  guestBookingWizardStep2Path: "/old/guest/booking_trips/:uuid/wizard/accessibility",
  guestBookingWizardStep3Path: "/old/guest/booking_trips/:uuid/wizard/payment",
  guestBookingWizardStep4Path: "/old/guest/booking_trips/:uuid/wizard/confirmation",

  // Client routes
  clientRootPath: "/old/client",
  clientFavoritesPath: "/old/client/favorites",
  clientMessagesPath: "/old/client/messages",
  clientProfileRootPath: "/old/client/profile/:id",
  clientSettingsRootPath: "/old/client/settings",
  clientSettingsPaymentPath: "/old/client/settings/payment",
  clientSettingsPasswordPath: "/old/client/settings/password",
  clientSettingsNotificationsPath: "/old/client/settings/notifications",

  clientBookingsPath: "/old/client/booking_trips",
  clientBookingsPastTripsPath: "/old/client/booking_trips/past",
  clientBookingPath: "/old/client/booking_trips/:uuid",
  clientBookingWizardRootPath: "/old/client/booking_trips/:uuid/wizard",
  clientBookingWizardStep1Path: "/old/client/booking_trips/:uuid/wizard/booking",
  clientBookingWizardStep2Path: "/old/client/booking_trips/:uuid/wizard/accessibility",
  clientBookingWizardStep3Path: "/old/client/booking_trips/:uuid/wizard/payment",
  clientBookingWizardStep4Path: "/old/client/booking_trips/:uuid/wizard/confirmation",

  publicGuidePath: "/old/guides/:slug",
  publicGuideTripsPath: "/old/guides/:slug/trips",
  publicGuideLocationPath: "/old/guides/:slug/location",
  publicGuideBoatsPath: "/old/guides/:slug/boats",
  publicGuidePostsPath: "/old/guides/:slug/posts",
  publicGuideReviewsPath: "/old/guides/:slug/reviews",

  // Guide routes
  guideRootPath: "/pro/home",
  guideDashboardPath: "/old/guide/dashboard",
  guideDashboardBookingNewPath: "/old/guide/dashboard/bookings/new",
  guideDashboardBookingEditPath: "/old/guide/dashboard/bookings/:id/edit",
  guideDashboardJournalNewPath: "/old/guide/dashboard/journal_notes/new",
  guideDashboardJournalEditPath: "/old/guide/dashboard/journal_notes/:id/edit",

  guideDashboardUnavailableDayManagePath: "/old/guide/dashboard/unavailable_days/manage",

  guideWizardRootPath: "/pro/home/wizard",
  guideWizardStep1Path: "/pro/home/wizard/choose-your-activities",
  guideWizardStep2Path: "/pro/home/wizard/your-profile",
  guideWizardStep3Path: "/pro/home/wizard/photos",
  guideWizardStep4Path: "/pro/home/wizard/extras",
  guideWizardStep5Path: "/pro/home/wizard/documents",
  guideWizardStep6Path: "/pro/home/wizard/submit",

  guideBookingsPath: "/pro/home/bookings",
  guideBookingNewPath: "/pro/home/bookings/new",
  guideBookingEditPath: "/pro/home/bookings/:id/edit",

  guideClientsPath: "/pro/home/clients",
  guideClientNewPath: "/pro/home/clients/new",
  guideClientPath: "/pro/home/clients/:id",
  guideClientEditPath: "/pro/home/clients/:id/edit",
  guideClientEmailActionPath: "/pro/home/clients/email_action",
  guideClientTextActionPath: "/pro/home/clients/text_action",
  guideClientMessageActionPath: "/pro/home/clients/message_action",

  guideTripsPath: "/pro/home/trips",
  guideTripPath: "/pro/home/trips/:id",
  guideTripWizardRootPath: "/pro/home/trips/:id/wizard",
  guideTripWizardStep1Path: "/pro/home/trips/:id/wizard/trip-details",
  guideTripWizardStep2Path: "/pro/home/trips/:id/wizard/photos",
  guideTripWizardStep3Path: "/pro/home/trips/:id/wizard/boats",
  guideTripWizardStep3AddNewBoatPath: "/pro/home/trips/:id/wizard/boats/new",
  guideTripWizardStep4Path: "/pro/home/trips/:id/wizard/location",
  guideTripWizardStep5Path: "/pro/home/trips/:id/wizard/preview",

  guideMessagesPath: "/pro/home/messages",
  guideAccountingPath: "/pro/home/accounting",
  guideAccountingAddExpancePath: "/pro/home/accounting/new",
  guideAccountingEditExpancePath: "/pro/home/accounting/:id/edit",

  guideBoatsPath: "/pro/home/boats",
  guideAddNewBoatPath: "/pro/home/boats/new",
  guideEditBoatPath: "/pro/home/boats/:id/edit",

  guideMarketingPath: "/pro/home/marketing",
  guideMarketingPostNewPath: "/pro/home/marketing/new",
  guideMarketingPostEditPath: "/pro/home/marketing/:id/edit",
  guideMarketingTemplatesPath: "/pro/home/marketing/templates",
  guideMarketingTemplatesParentsPath: "/pro/home/marketing/templates/parents",
  guideMarketingTemplateNewPath: "/pro/home/marketing/templates/new",
  guideMarketingTemplateEditPath: "/pro/home/marketing/templates/:id/edit",

  guideSettingsRootPath: "/pro/home/settings",
  guideSettingsPhotosPath: "/pro/home/settings/photos",
  guideSettingsExtrasPath: "/pro/home/settings/extras",
  guideSettingsDocumentsPath: "/pro/home/settings/documents",
  guideSettingsStripePath: "/pro/home/settings/stripe",
  guideSettingsSocialsPath: "/pro/home/settings/social-networks",
  guideSettingsPasswordPath: "/pro/home/settings/password",
  guideSettingsNotificationsPath: "/pro/home/settings/notifications",

  guideWeatherPath: "/pro/home/weather",

  guidePostPath: "/pro/home/posts/:id",
  guideProfileRootPath: "/pro/home/profile/:id",
  guideProfileContactsPath: "/pro/home/profile/:id/contacts",
  guideProfileMediaPath: "/pro/home/profile/:id/media",
  guideProfileTaggedPath: "/pro/home/profile/:id/tagged",
  guideProfileSettingsPath: "/pro/home/profile/:id/settings",
  guideContactsPath: "/pro/home/contacts",
  guideCommunitiesPath: "/pro/home/communities",
  guideCommunityPath: "/pro/home/communities/:id",
  guideNotificationsPath: "/pro/home/notifications",
  guideContactUsPath: "/pro/home/contact-us",
  guideChangePasswordPath: "/pro/home/change-password"
}

export const oldRoutes = Object.keys(ROUTES_LIST).reduce(
  (acc, routeAlias) => ({ ...acc, [routeAlias]: dynamicRoute(ROUTES_LIST[routeAlias]) }),
  {}
)
