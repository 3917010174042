import React from "react"
import { useSearchParams } from "react-router-dom"
import { Layout, Typography } from "antd"
import { useDevice } from "hooks/useDevice"
import { ChatHistory } from "./ChatHistory"
import { ConversationList } from "./ConversationList"

const { Content } = Layout
const { Title } = Typography

export const Messages = () => {
  const [searchParams] = useSearchParams()
  const { isMobile, isTablet } = useDevice()
  const selectedConversationId = +searchParams.get("conversation_id") || null
  const isSmallDevice = isMobile || isTablet
  const shouldRenderConversationList = !isSmallDevice || !selectedConversationId
  return (
    <div className="flex-column flex-grow-1">
      <Layout>
        {shouldRenderConversationList && <ConversationList selectedConversationId={selectedConversationId} isSmallDevice={isSmallDevice} />}
        <Layout style={{ flex: 1 }}>
          {selectedConversationId ? (
            <ChatHistory selectedConversationId={selectedConversationId} />
          ) : (
            !isSmallDevice && (
              <Content style={{ textAlign: "center", padding: "20px" }}>
                <Title level={3}>Select a conversation to start chatting</Title>
              </Content>
            )
          )}
        </Layout>
      </Layout>
    </div>
  )
}
