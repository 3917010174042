import { createAction, createReducer } from "@reduxjs/toolkit"

import { LOADING_INITIAL_STATE } from "modules/loader-watchers/helpers/loading"
import { oldRoutes } from "router/old-routes"
import { routes } from "router/routes"

// ACTIONS
export const setProHomeMenuItemActive = createAction("proHomeMenu/setMenuItemActive")
export const setProHomeMenuOpen = createAction("proHomeMenu/setMenuOpen")
export const setProHomeMenuClose = createAction("proHomeMenu/setMenuClose")

// REDUCER
const initialState = {
  ...LOADING_INITIAL_STATE,
  activeMenu: null,
  open: false,
  menuItems: [
    {
      groupName: "menu",
      id: "menu",
      items: [
        {
          name: "Dashboard",
          path: routes.guideDashboardPath(),
          id: "dashboard",
          iconName: "dashboard"
        },
        {
          name: "Bookings",
          path: routes.guideBookingsPath(),
          id: "bookings",
          iconName: "booking"
        },
        {
          name: "Calendar",
          path: "",
          id: "calendar",
          iconName: "calendar"
        },
        {
          name: "Clients",
          path: routes.guideClientsPath(),
          id: "clients",
          iconName: "user"
        },
        {
          name: "Messages",
          path: routes.guideMessagesPath(),
          id: "messages",
          iconName: "messages"
        },
        {
          name: "Marketing",
          path: routes.guideMarketingPath(),
          id: "marketing",
          iconName: "marketing"
        },
        {
          name: "Accounting",
          path: routes.guideAccountingPath(),
          id: "accounting",
          iconName: "finances"
        },
        {
          name: "Resources",
          path: routes.guideBoatsPath(),
          id: "boats",
          iconName: "boat"
        },
        {
          name: "Gallery",
          path: "",
          id: "gallery",
          iconName: "gallery"
        },
        {
          name: "Experiences",
          path: routes.guideTripsPath(),
          id: "trips",
          iconName: "experiences"
        },
        {
          name: "Notifications",
          path: "",
          id: "notifications",
          iconName: "notification"
        }
      ]
    },
    {
      groupName: "account",
      id: "account",
      items: [
        {
          name: "Settings",
          path: routes.guideSettingsRootPath(),
          id: "settings",
          iconName: "settings"
        },
        {
          name: "QR Codes",
          path: "",
          id: "qr-codes",
          iconName: "qr"
        }
      ]
    },
    {
      groupName: "help",
      id: "help",
      items: [
        {
          name: "FAQs",
          path: "",
          id: "faqs",
          iconName: "faq"
        },
        {
          name: "Contact Support",
          path: "",
          id: "contact-support",
          iconName: "support"
        }
      ]
    }
  ]
}

const proHomeMenuReducer = createReducer(initialState, {
  [setProHomeMenuItemActive.type]: (state, action) => {
    state.activeMenu = action.payload
  },
  [setProHomeMenuOpen.type]: (state) => {
    state.open = true
  },
  [setProHomeMenuClose.type]: (state) => {
    state.open = false
  }
})

export default proHomeMenuReducer
