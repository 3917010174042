import React, { useEffect } from "react"
import { Outlet, useParams } from "react-router-dom"
import { oldRoutes } from "router/old-routes"
import { Col, Row } from "reactstrap"
import SidebarNavigation from "components/trips/wizard/SidebarNavigation"
import withDefaultErrorBoundary from "modules/errors/HOCs/withDefaultErrorBoundary"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"
import FirstLoading from "modules/loader-watchers/FirstLoading"

import { useWizardRedirects } from "hooks/wizard"
import { useMedia } from "modules/window/hooks/useMedia"
import { useScrollToActive } from "hooks/useScrollToActive"
import useLoaderWatchers from "modules/loader-watchers/hooks/useLoaderWatchers"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { getGuideTrip } from "store/trips"

function TripWizardLayout() {
  useLoaderWatchers({ watchers: "trips.trip" })
  const params = useParams()
  const wizardNavigationRef = useScrollToActive()
  const mobileWizardNavigationRef = useScrollToActive()
  const fromMd = useMedia({ from: "md" })
  const dispatch = useDispatch()
  const redirect = useWizardRedirects({
    name: "trips.trip",
    rootPath: oldRoutes.guideTripWizardRootPath({ id: params.id }),
    exitPath: oldRoutes.guideTripsPath()
  })
  const headerHeight = useSelector((state) => state.window.headerHeight)

  useEffect(() => {
    if (params.id) dispatch(getGuideTrip(params.id))
  }, [params.id]) //eslint-disable-line

  return (
    redirect || (
      <Row className="flex-fill min-h-100 gx-30 align-content-start bg-light my-n30 z-0">
        {fromMd ? (
          <Col md={4} lg={3} className="vstack sticky-top" style={{ top: headerHeight, height: `calc(100vh - ${headerHeight}px)` }}>
            <div className="vstack overflow-auto pe-40 bg-white" ref={wizardNavigationRef}>
              <div className="position-absolute top-0 end-100 z-0 vw-100 h-100 me-n20 bg-white" />
              <div className="vstack py-20 py-md-30 z-1 position-relative">
                <SidebarNavigation />
              </div>
            </div>
          </Col>
        ) : (
          <Col xs={12} className="sticky-top" style={{ top: headerHeight }}>
            <div className="full-window-scroll bg-white shadow" ref={mobileWizardNavigationRef}>
              <div className="py-10 py-sm-20">
                <SidebarNavigation />
              </div>
            </div>
          </Col>
        )}
        <Col xs={12} md={8} lg={9} className="pt-30 pb-50">
          <FirstLoading name="trips.trip">
            <DefaultErrorBoundary>
              <Outlet />
            </DefaultErrorBoundary>
          </FirstLoading>
        </Col>
      </Row>
    )
  )
}

export default withDefaultErrorBoundary(TripWizardLayout)
