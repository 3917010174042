import React from "react"
import { createHashRouter, Navigate } from "react-router-dom"
import { routes } from "./routes.js"
import PrivateRoutes from "./PrivateRoutes"
import PublicRoutes from "./PublicRoutes"

import RouteChangeWatcher from "components/RouteChangeWatcher"
import RouteErrorBoundary from "modules/errors/RouteErrorBoundary"

import ScrollToTopLayout from "layouts/ScrollToTopLayout"
import DeviseLayout from "layouts/DeviseLayout"

// new routes
import { AntdComps } from "pages/AntdComps.js"

// authentication new routes
import { SignIn as SignInV2 } from "pagesV2/auth/SignIn"
import { SignUp as SignUpV2 } from "pagesV2/auth/SignUp"
import { SignUpOptions } from "pagesV2/auth/SignUp/Options"
import { NewPassword } from "pagesV2/auth/NewPassword"
import { ConfirmEmail } from "pagesV2/auth/SignUp/ConfirmEmail"
import { ConfirmPhone } from "pagesV2/auth/SignUp/ConfirmPhone"
import { ConfirmInfo } from "pagesV2/auth/SignUp/ConfirmInfo"
import { PasswordRecovery } from "pagesV2/auth/PasswordRecovery"
import { PasswordRecoveryEnterCode } from "pagesV2/auth/PasswordRecovery/EnterCode"
import { PasswordRecoveryCreatePassword } from "pagesV2/auth/PasswordRecovery/CreatePassword"
import { PasswordRecoverySuccess } from "pagesV2/auth/PasswordRecovery/Success"

// new layouts
import { PersonalInfoLayout } from "layoutsV2/pro/PersonalInfoLayout/PersonalInfoLayout.jsx"
import { ProfileInformation as PersonalInfoProfileInformation } from "ui/PersonalInfo/ProfileInformation"
import { PersonalInfoAddresses } from "ui/PersonalInfo/Addresses"
import { AdventureModeSelect } from "ui/AdventureModeSelect"

import { oldRouter, guideRoutes } from "./old-router"
import { ProHeader } from "layoutsV2/pro/ProHeader"
import { ProHomeLayout } from "layoutsV2/pro/ProHomeLayout"
import { ProMessagesLayout } from "layoutsV2/pro/ProMessagesLayout"
import { ProSettingsLayout } from "layoutsV2/pro/ProSettingsLayout"
import { ProSettings } from "pagesV2/pro/ProSettings"
import { ProfileInformation } from "pagesV2/pro/ProfileInformation"
import { StripeAccount } from "pagesV2/pro/StripeAccount"
import { Messages } from "pagesV2/pro/Messages"
import LandingLayout from "layouts/LandingLayout.js"
import Landing from "pages/LandingPage.js"

// old pages
import GuideDashboardPage from "pages/guide/dashboard/DashboardPage"
import GuideJournalWindow from "pages/guide/dashboard/JournalWindow"
import GuideUnavailableDayWindow from "pages/guide/dashboard/UnavailableDayWindow"
import GuideDashboardBookingWindow from "pages/guide/dashboard/BookingWindow"

import GuideBookingsPage from "pages/guide/bookings/BookingsPage"
import GuideBookingWindow from "pages/guide/bookings/BookingWindow"

import GuideClients from "pages/guide/clients/ClientsPage"
import GuideClientWindow from "pages/guide/clients/ClientWindow"
import GuideClientActionWindow from "pages/guide/clients/ActionWindow"

import GuideTrips from "pages/guide/trips/TripsPage"
import Trip from "pages/public/trips/TripPage"

import Boats from "pages/guide/boats/BoatsPage"
import BoatWindow from "pages/guide/boats/BoatWindow"

// import MessagesPage from "pages/private/messages/MessagesPage"

import GuideAccounting from "pages/guide/accouting/Accounting"
import GuideAccountingExpenseWindow from "pages/guide/accouting/ExpenseWindow"

import GuideMarketing from "pages/guide/marketing/MarketingPage"
import GuideFeeder from "pages/guide/marketing/FeederPage"
import GuidePostWindow from "pages/guide/marketing/PostWindow"
import GuideTemplates from "pages/guide/marketing/TemplatesPage"
import GuideTemplatesParents from "pages/guide/marketing/TemplatesParentsPage"
import GuideTemplate from "pages/guide/marketing/TemplatePage"

import GuideSettingsLayout from "layouts/guide/SettingsLayout"
import GuideSettingsDetails from "pages/guide/settings/MyDetailsPage"
import GuideSettingsPhotos from "pages/guide/settings/PhotosPage"
import GuideSettingsExtras from "pages/guide/settings/ExtrasPage"
import GuideSettingsDocuments from "pages/guide/settings/DocumentsPage"
import GuideSettingsStripe from "pages/guide/settings/StripePage"
import GuideSettingsSocials from "pages/guide/settings/SocialsPage"
import GuideSettingsLoginAndSecurity from "pages/guide/settings/LoginAndSecurityPage"
import GuideSettingsNotifications from "pages/guide/settings/NotificationsPage"

export const router = createHashRouter([
  {
    element: (
      <RouteChangeWatcher>
        <ScrollToTopLayout />
      </RouteChangeWatcher>
    ),
    errorElement: (
      <DeviseLayout>
        <RouteErrorBoundary />
      </DeviseLayout>
    ),
    children: [
      ...oldRouter,
      {
        // Public only routes
        element: <PublicRoutes only />,
        children: [
          {
            element: <AntdComps />,
            path: routes.antd()
          },
          {
            element: <LandingLayout />,
            children: [{ path: routes.rootPath(), element: <Landing /> }]
          },
          // v2 paths start
          { path: routes.signInV2(), element: <SignInV2 /> },
          { path: routes.signUpV2(), element: <SignUpV2 /> },
          { path: routes.newPassword(), element: <NewPassword /> },
          { path: routes.signUpOptions(), element: <SignUpOptions /> },
          { path: routes.confirmEmail(), element: <ConfirmEmail /> },
          { path: routes.confirmPhone(), element: <ConfirmPhone /> },
          { path: routes.confirmInfo(), element: <ConfirmInfo /> },
          { path: routes.passwordRecovery(), element: <PasswordRecovery /> },
          { path: routes.passwordRecoveryEnterCode(), element: <PasswordRecoveryEnterCode /> },
          { path: routes.passwordRecoveryCreatePassword(), element: <PasswordRecoveryCreatePassword /> },
          { path: routes.passwordRecoverySuccess(), element: <PasswordRecoverySuccess /> }
          // v2 paths end
        ]
      },
      // V2 Guide routes
      {
        path: routes.proRootPath(),
        element: <PrivateRoutes accessRole="guide_user" />,
        children: [
          {
            path: routes.proRootPath(),
            element: <ProHeader />,
            children: [
              {
                path: routes.proHomeRootPath(),
                element: <ProHomeLayout />,
                children: [
                  // {
                  //   index: true,
                  //   element: <Navigate to={routes.proSettingsRootPath()} />
                  // },
                  // {
                  //   path: routes.proSettingsRootPath(),
                  //   element: <ProSettingsLayout />,
                  //   children: [
                  //     { index: true, element: <ProSettings /> },
                  //     { path: routes.proSettingsStripeAccount(), element: <StripeAccount /> },
                  //     { path: routes.proSettingsProfileInformation(), element: <ProfileInformation /> }
                  //   ]
                  // },
                  // {
                  //   element: <ProMessagesLayout />,
                  //   path: routes.proMessagesRootPath(),
                  //   // element: <Messages />
                  //   children: [{ index: true, element: <Messages /> }]
                  // },
                  {
                    path: routes.guideDashboardPath(),
                    element: <GuideDashboardPage />,
                    children: [
                      { path: routes.guideDashboardJournalNewPath(), element: <GuideJournalWindow /> },
                      { path: routes.guideDashboardJournalEditPath(), element: <GuideJournalWindow /> },
                      { path: routes.guideDashboardBookingNewPath(), element: <GuideDashboardBookingWindow /> },
                      { path: routes.guideDashboardBookingEditPath(), element: <GuideDashboardBookingWindow /> },
                      { path: routes.guideDashboardUnavailableDayManagePath(), element: <GuideUnavailableDayWindow /> }
                    ]
                  },
                  {
                    path: routes.guideBookingsPath(),
                    element: <GuideBookingsPage />,
                    children: [
                      { path: routes.guideBookingEditPath(), element: <GuideBookingWindow /> },
                      { path: routes.guideBookingNewPath(), element: <GuideBookingWindow /> }
                    ]
                  },
                  {
                    path: routes.guideClientsPath(),
                    element: <GuideClients />,
                    children: [
                      { path: routes.guideClientNewPath(), element: <GuideClientWindow /> },
                      { path: routes.guideClientPath(), element: <GuideClientWindow /> },
                      { path: routes.guideClientEditPath(), element: <GuideClientWindow /> },
                      { path: routes.guideClientEmailActionPath(), element: <GuideClientActionWindow type="email" /> },
                      { path: routes.guideClientTextActionPath(), element: <GuideClientActionWindow type="text" /> },
                      { path: routes.guideClientMessageActionPath(), element: <GuideClientActionWindow type="message" /> }
                    ]
                  },
                  { path: routes.guideTripsPath(), element: <GuideTrips /> },
                  { path: routes.guideTripPath(), element: <Trip guideView /> },
                  {
                    path: routes.guideBoatsPath(),
                    element: <Boats />,
                    children: [
                      { path: routes.guideAddNewBoatPath(), element: <BoatWindow /> },
                      { path: routes.guideEditBoatPath(), element: <BoatWindow /> }
                    ]
                  },
                  { path: routes.guideMessagesPath(), element: <Messages /> },
                  {
                    path: routes.guideAccountingPath(),
                    element: <GuideAccounting />,
                    children: [
                      { path: routes.guideAccountingAddExpancePath(), element: <GuideAccountingExpenseWindow /> },
                      { path: routes.guideAccountingEditExpancePath(), element: <GuideAccountingExpenseWindow /> }
                    ]
                  },
                  {
                    element: <GuideMarketing />,
                    children: [
                      {
                        path: routes.guideMarketingPath(),
                        element: <GuideFeeder />,
                        children: [
                          { path: routes.guideMarketingPostNewPath(), element: <GuidePostWindow /> },
                          { path: routes.guideMarketingPostEditPath(), element: <GuidePostWindow /> }
                        ]
                      },
                      {
                        path: routes.guideMarketingTemplatesPath(),
                        element: <GuideTemplates />,
                        children: [
                          {
                            path: routes.guideMarketingTemplatesParentsPath(),
                            element: <GuideTemplatesParents />
                          },
                          { path: routes.guideMarketingTemplateNewPath(), element: <GuideTemplate /> },
                          { path: routes.guideMarketingTemplateEditPath(), element: <GuideTemplate /> }
                        ]
                      }
                    ]
                  },

                  {
                    element: <GuideSettingsLayout />,
                    children: [
                      { path: routes.guideSettingsRootPath(), element: <GuideSettingsDetails /> },
                      { path: routes.guideSettingsPhotosPath(), element: <GuideSettingsPhotos /> },
                      { path: routes.guideSettingsExtrasPath(), element: <GuideSettingsExtras /> },
                      { path: routes.guideSettingsDocumentsPath(), element: <GuideSettingsDocuments /> },
                      { path: routes.guideSettingsStripePath(), element: <GuideSettingsStripe /> },
                      { path: routes.guideSettingsSocialsPath(), element: <GuideSettingsSocials /> },
                      { path: routes.guideSettingsPasswordPath(), element: <GuideSettingsLoginAndSecurity /> },
                      { path: routes.guideSettingsNotificationsPath(), element: <GuideSettingsNotifications /> }
                    ]
                  }
                ]
              },
              {
                path: routes.proPersonalInfoRootPath(),
                element: <PersonalInfoLayout />,
                children: [
                  { index: true, element: <Navigate to={routes.proPersonalInfoProfileInfo()} /> },
                  { path: routes.proPersonalInfoProfileInfo(), element: <PersonalInfoProfileInformation /> },
                  { path: routes.proPersonalInfoAddressesPath(), element: <PersonalInfoAddresses /> },
                  { path: routes.proPersonalInfAdventureMode(), element: <AdventureModeSelect /> }
                ]
              },
              // add here old routes
              ...guideRoutes
            ]
          }
        ]
      }
    ]
  }
])
