import React from "react"
import { Button } from "reactstrap"
import Icon from "components/common/Icon"

import { useTranslation } from "react-i18next"
import { useConfirmModal } from "modules/modals/hooks/useConfirmModal"

import { useDispatch, useSelector } from "react-redux"
import { isPlatformConnectedSelector, platformConnect, platformDisconnect } from "store/user"

import facebookIcon from "assets/icons/new/facebook.svg"
import instagramIcon from "assets/icons/new/instagram.svg"
import twitterIcon from "assets/icons/new/twitter.svg"

const SocialsForm = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const confirmModal = useConfirmModal()

  const isTwitterConnected = useSelector(isPlatformConnectedSelector("twitter"))
  const isFacebookConnected = useSelector(isPlatformConnectedSelector("facebook"))
  const isInstagramConnected = useSelector(isPlatformConnectedSelector("instagram"))

  const connectPlatformAccount = (platform) => () => dispatch(platformConnect(platform))

  const disconnectPlatformAccount = (platform) =>
    confirmModal(
      {
        title: `Are you sure you want to disconnect?`,
        submitText: t("global.disconnect"),
        cancelText: t("global.cancel"),
        color: "danger",
        props: { size: "md" }
      },
      () => dispatch(platformDisconnect(platform))
    )

  console.log("platforms", { isTwitterConnected, isFacebookConnected, isInstagramConnected })

  return (
    <div className="text-start vstack gap-y-3">
      <div className="bg-white rounded py-20">
        <h2 className="h4 fw-medium">{t("guide.extras.twitter_account")}</h2>
        <div className="vstack gap-10 mt-15">
          <div className="hstack gap-30 justify-content-between">
            <img src={twitterIcon} alt={""} />
            {isTwitterConnected ? (
              <div className="hstack gap-10">
                <div className="bg-primary bg-opacity-10 rounded-pill hstack gap-2 fs-4 fw-semibold py-2 px-30 text-primary">
                  Connected
                  <Icon iconName="Valid" />
                </div>
                <Button color="danger" outline className="p-1" onClick={disconnectPlatformAccount("twitter")}>
                  <Icon iconName="Close" size={30} />
                </Button>
              </div>
            ) : (
              <Button color="primary-second" onClick={connectPlatformAccount("twitter")}>
                Connect
              </Button>
            )}
          </div>
        </div>
      </div>

      <div className="bg-white rounded py-20">
        <h2 className="h4 fw-medium">{t("guide.extras.facebook_account")}</h2>
        <div className="vstack gap-10 mt-15">
          <div className="hstack gap-30 justify-content-between">
            <img src={facebookIcon} alt={""} />
            {isFacebookConnected ? (
              <div className="hstack gap-10">
                <div className="bg-primary bg-opacity-10 rounded-pill hstack gap-2 fs-4 fw-semibold py-2 px-30 text-primary">
                  Connected
                  <Icon iconName="Valid" />
                </div>
                <Button color="danger" outline className="p-1" onClick={disconnectPlatformAccount("facebook")}>
                  <Icon iconName="Close" size={30} />
                </Button>
              </div>
            ) : (
              <Button color="primary-second" onClick={connectPlatformAccount("facebook")}>
                Connect
              </Button>
            )}
          </div>
        </div>
      </div>

      <div className="bg-white rounded py-20">
        <h2 className="h4 fw-medium">{t("guide.extras.instagram_account")}</h2>
        <div className="vstack gap-10 mt-15">
          <div className="hstack gap-30 justify-content-between">
            <img src={instagramIcon} alt={""} />
            {isInstagramConnected ? (
              <div className="hstack gap-10">
                <div className="bg-primary bg-opacity-10 rounded-pill hstack gap-2 fs-4 fw-semibold py-2 px-30 text-primary">
                  Connected
                  <Icon iconName="Valid" />
                </div>
                <Button color="danger" outline className="p-1" onClick={disconnectPlatformAccount("instagram")}>
                  <Icon iconName="Close" size={30} />
                </Button>
              </div>
            ) : (
              <Button color="primary-second" className="d-flex gap-2" onClick={connectPlatformAccount("instagram")}>
                Connect
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SocialsForm
