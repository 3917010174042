export const getQueryParamRemove = (param) => {
  const hash = window.location.hash
  const queryString = hash.split("?")[1]

  let paramValue = null

  if (queryString) {
    const urlParams = new URLSearchParams(queryString)
    paramValue = urlParams.get(param)
    urlParams.delete(param)
    const newHash = hash.split("?")[0] + "?" + urlParams.toString()
    window.history.replaceState(null, "", newHash)
  }

  return paramValue
}
