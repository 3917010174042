import React, { useMemo } from "react"
import { Label } from "reactstrap"
import Input from "components/form/Input"
import ActionButtons from "components/guide/wizard/ActionButtons"
import Icon from "components/common/Icon"

import useForm, { cleanNestedAttributes, useNestedFields } from "hooks/useForm"
import { useTranslation } from "react-i18next"
import { serialize } from "object-to-formdata"
import { find } from "lodash"

import { useDispatch, useSelector } from "react-redux"
import { guideSelector, updateGuide } from "store/user"

const getFields = (activity_type) => ["driver_license", "guide_license", "capitan_license", `${activity_type}_permit`, "waiver", "other"]

const DocumentsForm = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const guide = useSelector(guideSelector)
  const [form, changeHandler, submitHandler, submitCallback, reset, isChanged] = useForm(guide, ["documents"])
  const [, , addDocumentHandler, , , updateDocumentHandler] = useNestedFields(guide, "documents", ["file", "file_type"], changeHandler)
  const fileds = useMemo(() => getFields(guide.activity_type), [guide.activity_type])

  const onChangeHandler =
    ({ id, _id } = {}, file_type) =>
    (event) => {
      const [file] = event.target.files
      if (!file) return

      if (id || _id) updateDocumentHandler(null, { id, _id, file, file_type })
      else addDocumentHandler(null, { file, file_type })
    }

  submitCallback(() => {
    const documents_attributes = cleanNestedAttributes(form.documents, ["file", "file_type"])
    const guideAttributes = { ...form, documents_attributes }

    const formData = serialize({ guide: guideAttributes }, { indices: true })
    dispatch(updateGuide(guide.id, formData))
  })

  return (
    <form onSubmit={submitHandler}>
      <div className="vstack gap-10 bg-white rounded">
        {fileds.map((field) => {
          const document = find(form.documents, { file_type: field })
          const isPersisted = !!document?.url
          return (
            <div key={field} className="d-flex flex-wrap">
              <Label for={field} className="fs-6">
                {t(`guide.documents.${field}`)}
              </Label>
              <div className="d-block position-relative w-100">
                <Input
                  placeholder={t(`guide.documents.${field}`)}
                  value={document?.file?.name || document?.filename || ""}
                  className="fs-6"
                  valid={isPersisted}
                  readOnly
                />
                {!isPersisted && (
                  <Icon iconName="Upload" className="text-primary-second position-absolute top-50 end-0 translate-middle-y me-2" />
                )}
                <Input id={field} type="file" onChange={onChangeHandler(document, field)} className="cover opacity-0" />
              </div>
            </div>
          )
        })}
      </div>
      <ActionButtons withSkip isChanged={isChanged} onReset={reset} />
    </form>
  )
}

export default DocumentsForm
