import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { Dropdown, DropdownItem } from "components/common/Dropdown"
import { Button, DropdownToggle } from "reactstrap"
import { Link, useSearchParams } from "react-router-dom"
import { routes } from "router"
import Icon from "components/common/Icon"
import DateSwitcher from "modules/datepicker/DateSwitcher"
// import Input from "components/form/Input"

import { useTranslation } from "react-i18next"
// import { weatherImage } from "helpers/weather"

import { DEFAULT_RANGE, RANGE_OPTIONS } from "constants/dashboard"

const Header = ({ className, params, onChangeFilters }) => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  searchParams.delete("tripId")
  searchParams.delete("tripDate")
  const date = useMemo(() => (params.date ? global.dateTime(params.date) : null), [params.date])

  useEffect(() => {
    if (!params.range) onChangeFilters({ target: { value: DEFAULT_RANGE, name: "range" } })
  }, [params.range]) //eslint-disable-line

  const classes = ["hstack gap-20 p-15"]
  if (className) classes.push(className)

  if (!params.range) return null

  return (
    <div className={classes.join(" ")}>
      <DateSwitcher
        value={date}
        defaultValue={global.dateTime().startOf("date")}
        name="date"
        onChange={onChangeFilters}
        type={params.range}
      />
      <Dropdown
        className="my-n1"
        toggleButton={
          <DropdownToggle color="primary-second" outline className="hstack gap-2 rounded-pill py-2 px-15">
            <span className="fs-7 fw-medium lh-1">{RANGE_OPTIONS[params.range]}</span>
            <Icon iconName="SidebarToggle" size={14} />
          </DropdownToggle>
        }
      >
        {Object.entries(RANGE_OPTIONS).map(([range, label], index) => (
          <DropdownItem key={index} name="range" value={range} onClick={onChangeFilters} active={params.range === range}>
            {label}
          </DropdownItem>
        ))}
      </Dropdown>
      <div className="flex-fill" />
      {/* <Label
          className={[
            "hstack gap-1 justify-content-between rounded-pill p-1 m-auto position-relative",
            params.weather ? "flex-row-reverse bg-primary-second" : "bg-dark bg-opacity-50"
          ].join(" ")}
          style={{ width: 66 }}
        >
          <div className="p-3 bg-white rounded-circle" />
          <img src={weatherImage(28)} alt="" height="26" className={["mx-n10 my-n2", params.weather ? "" : "filter-wb"].join(" ")} />
          <Input
            type="checkbox"
            checked={params.weather || false}
            onChange={changeWeatherHandler}
            className="position-absolute start-0 top-0 w-100 h-100 m-0 opacity-0 cursor-pointer"
          />
        </Label> */}

      <div className="hstack gap-10">
        <Button color="primary" tag={Link} className="px-20 py-10 fs-7" to={routes.guideDashboardBookingNewPath({}, searchParams)}>
          {t("bookings.add")}
        </Button>
        <Button
          color="primary"
          tag={Link}
          outline
          className="px-20 py-10 fs-7 text-white-hover"
          to={routes.guideDashboardUnavailableDayManagePath({}, searchParams)}
        >
          {t("unavailable_days.manage")}
        </Button>
      </div>
    </div>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  params: PropTypes.shape({
    date: PropTypes.string,
    range: PropTypes.string,
    weather: PropTypes.bool
  }).isRequired,
  onChangeFilters: PropTypes.func.isRequired
}

export default Header
