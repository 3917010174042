import React, { useEffect, useState } from "react"
import { CloseButton, Offcanvas } from "react-bootstrap"
import { useMatch, useNavigate, useSearchParams } from "react-router-dom"
import { routes } from "router"
import Form from "components/journals/Form"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"

import { useTranslation } from "react-i18next"
import { useConfirmModal } from "modules/modals/hooks/useConfirmModal"
// Redux
import { useDispatch, useSelector } from "react-redux"
import { cleanJournal, getGuideJournal } from "store/dashboard"

export default function JournalWindow() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const confirmModal = useConfirmModal()
  const isPersisted = useMatch(routes.guideDashboardJournalEditPath())
  const journalId = isPersisted?.params?.id
  const headerHeight = useSelector((state) => state.window.headerHeight)
  const { loading } = useSelector((state) => state.dashboard)
  const [isOpen, setIsOpen] = useState(false)
  const [isChanged, setIsChanged] = useState(false)
  const [searchParams] = useSearchParams()

  const closeHandler = isChanged
    ? confirmModal(
        {
          title: "Are you sure you want to discard all changes?",
          color: "danger",
          submitText: t("global.discard"),
          cancelText: t("global.cancel")
        },
        () => setIsOpen(false)
      )
    : () => setIsOpen(false)

  const exitHandler = () => {
    searchParams.delete("journal_date")
    navigate(routes.guideDashboardPath({}, searchParams))
  }

  useEffect(() => {
    if (journalId && !loading) dispatch(getGuideJournal(journalId))
    return () => dispatch(cleanJournal())
  }, [journalId, loading, dispatch])

  useEffect(() => {
    setIsOpen(true)
  }, [])

  return (
    <Offcanvas
      placement="end"
      scroll
      show={isOpen}
      onHide={closeHandler}
      onExited={exitHandler}
      container={document.getElementById("dashboard")}
      className="border-0 shadow"
      backdropClassName="bg-transparent"
      style={{
        top: headerHeight,
        width: 850
      }}
    >
      <CloseButton
        onClick={closeHandler}
        className="d-block d-md-none position-absolute"
        style={{ zIndex: 2000, top: "10px", right: "10px" }}
      />
      <Offcanvas.Body className="vstack p-20">
        <DefaultErrorBoundary>
          <Form onChanged={setIsChanged} edit={!!journalId} />
        </DefaultErrorBoundary>
      </Offcanvas.Body>
    </Offcanvas>
  )
}
