import React, { createContext, useContext, useState } from "react"
import { useSelector } from "react-redux"
import { ConfigProvider } from "antd"
import { sportsmanTheme } from "themes/sportsman"
import { sportswomanTheme } from "themes/sportswoman"
import { themes as themeNames } from "constants/theme"
import { getQueryParamRemove } from "utils/query-params"

const themes = {
  [themeNames.sportsman]: sportsmanTheme,
  [themeNames.sportswoman]: sportswomanTheme
}

const THEME_KEY = "DS-theme"

const ThemeContext = createContext()

export const AntdThemeProvider = ({ children }) => {
  let queryParamDsTheme = getQueryParamRemove("ds-theme")
  queryParamDsTheme = queryParamDsTheme === themeNames.sportsman || queryParamDsTheme === themeNames.sportswoman ? queryParamDsTheme : null

  const userThemeMode = useSelector((state) => state.user.adventure_mode)
  const storageThemeMode = localStorage.getItem(THEME_KEY)
  const defaultMode = queryParamDsTheme || userThemeMode || storageThemeMode || themeNames.sportsman
  const [theme, setTheme] = useState(defaultMode)
  const bodyClassList = document.body.classList

  const themeToRemove = theme === themeNames.sportsman ? themeNames.sportswoman : themeNames.sportsman
  if (bodyClassList.contains(`theme-${themeToRemove}`)) {
    bodyClassList.remove(`theme-${themeToRemove}`)
  }

  bodyClassList.add(`theme-${theme}`)

  const switchTheme = (newTheme) => {
    bodyClassList.remove(`theme-${theme}`)
    bodyClassList.add(`theme-${newTheme}`)
    setTheme(newTheme)
  }

  const saveThemeToStorage = (theme) => {
    localStorage.setItem(THEME_KEY, theme)
  }

  if (queryParamDsTheme && queryParamDsTheme === defaultMode) {
    saveThemeToStorage(queryParamDsTheme)
  }

  return (
    <ThemeContext.Provider
      value={{
        theme,
        isSportsman: theme === themeNames.sportsman,
        isSportswoman: theme === themeNames.sportswoman,
        switchTheme,
        saveThemeToStorage
      }}
    >
      <ConfigProvider theme={themes[theme]}>{children}</ConfigProvider>
    </ThemeContext.Provider>
  )
}

export const useTheme = () => useContext(ThemeContext)
